<template>
  <pie-chart
    class-names="mt-8"
    title="Meilleures catégories"
    subTitle="dans discover (par nombre d'articles)"
    :series="series"
    :labels="labels"
    :colors="colors"
    :formatter="formatter"
  ></pie-chart>
</template>

<script>
import PieChart from "@/components/common/charts/PieChart.vue";
import { formatNum } from "leaflet/src/core/Util";

export default {
  name: "GlobalTopCategoriesGD",
  components: {
    PieChart,
  },
  async created() {
    await this.getData();
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnerViews: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      series: [],
      labels: [],
      colors: ["#4285F4", "#EA4335", "#34A853", "#FBBC05", "#FF6D01"],
      formatter: (val) => formatNum(val, 2),
    };
  },
  methods: {
    async getData() {
      const l = [];
      const s = [];
      const data = this.results;
      if (!data) return;
      for (const value of data) {
        l.push(value.category);
        s.push(value.count);
      }
      this.labels = l;
      this.series = s;
    },
  },
  computed: {
    results() {
      return this.$store.getters["gd/getData"]["categoriesDistributionGD"];
    },
  },
  watch: {
    results() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
